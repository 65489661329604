import type { RemoteCampaign } from '../types/CampaignTypes';
import publicApis from '@/apis/public.apis';
import useVideo from './useVideo';

const deviceTypeMap = {
    pc: { width: 1440, height: 768 },
    mobile: { width: 375, height: 667 },
    tablet: { width: 767, height: 1024 }
};
interface ThumbnailBase {
    addFix: false;
    deviceType: 'pc' | 'mobile' | 'tablet';
}

interface ThumbnailUrl extends ThumbnailBase {
    url: string;
    width?: number;
    height?: number;
    isDelay?: boolean;
    // customDomSelector?: string;
}

interface ThumbnailHTML extends ThumbnailBase {
    htmlString: string;
    customDomSelector: string;
}

/*
v3 没popup stickybar
{
    url: string;
    width: number;
    height: number;
    addFix: false;
    deviceType: 'pc' | 'mobile' | 'tablet';
}

v3 有popup stickybar
{
    htmlString: string;
    customDomSelector: string;
    addFix: false;
    deviceType: 'pc' | 'mobile' | 'tablet';
}

v2
{
    htmlString: string;
    customDomSelector: string;
    addFix: false;
    deviceType: 'pc' | 'mobile' | 'tablet';
}

v2 控制组不发
*/

type Thumbnail = XOR<ThumbnailUrl, ThumbnailHTML>;

export default async function addVersionScreenshot(remoteData: RemoteCampaign) {
    await Promise.all(remoteData?.versions?.map(async version => {
        try {
            // 有pc优先截
            const terminals =
                version
                ?.terminals
                ?.slice()
                ?.sort((terminalA, terminalB) => terminalA.type === 2 && terminalB.type !== 2 ? -1 : 1);

            const thumbnail = { addFix: false, deviceType: 'pc' } as Thumbnail;

            let targetTerminal;
            if (remoteData.engageVersion === '3.0') {

                // 优先截 popup然后是stickybar
                targetTerminal = terminals.find(terminal => terminal.popup?.length > 0 || terminal.stickybar?.length > 0);
                if (targetTerminal) thumbnail.customDomSelector = `[data-menu-action="insert.popup"]`;
                else { // 没有就截整页的图
                    thumbnail.url = remoteData.baseURL;
                }
            } else {
                targetTerminal = terminals.find((terminal: any) => terminal?.widgets?.[0]?.widgetMode);
                if (targetTerminal === undefined) return;
                const widgetMode: 'visualModel' | 'codeModel' = (targetTerminal as any).widgets?.[0]?.widgetMode;
                if (widgetMode === 'visualModel' && (targetTerminal as any)?.widgets?.[0]?.widgetNodes?.html?.indexOf?.('data-engage-block="engage"') !== -1)
                    thumbnail.customDomSelector = `[data-engage-block="engage"]`;
                else thumbnail.customDomSelector = `body > *:nth-child(1)`;
            }

            if (!targetTerminal) {
                if (terminals?.[0])
                    thumbnail.deviceType = terminals[0].type === 2 ? 'pc' : terminals[0].type === 1 ? 'mobile' : 'tablet';
                thumbnail.width = deviceTypeMap[thumbnail.deviceType].width;
                thumbnail.height = deviceTypeMap[thumbnail.deviceType].height;
            } else {
                let target: any | undefined = targetTerminal; // 统一v2 v3 widgetNodes入口
                if (remoteData.engageVersion === '3.0') {
                    const targetPop: any[]  = targetTerminal.popup.length > 0 ? targetTerminal.popup : targetTerminal.stickybar;
                    target = targetPop?.[0];
                }

                const video = useVideo(target?.widgets?.[0]?.widgetNodes?.html);
                if (video) {
                    thumbnail.isDelay = true;
                }
                thumbnail.htmlString = `
                    <body>
                        <style type="text/css">
                            ${target?.widgets?.[0]?.widgetNodes?.commonStyle || ''}
                            ${target?.widgets?.[0]?.widgetNodes?.css || ''}
                            * {
                                animation: none !important;
                                transition: none !important;
                            }
                        </style>
                        ${target?.widgets?.[0]?.widgetNodes?.html}
                        ${video ? video : ''}
                    </body>
                `;
            }

            if (remoteData.engageVersion !== '3.0'
                && (
                    Boolean(version.controlGroup) === true
                    || !targetTerminal
                )
            ) // v2控制组或者未完成无图
                version.screenshot = "";
            else {
                const img = await publicApis
                .otherServiceApi(null, {
                    where: {
                        service: 'draw',
                        type: 'post',
                        path: '/api/html2img',
                        body: thumbnail
                    }
                });
                if (img?.url) version.screenshot = img?.url
            }
        } catch(e) {

        }
    }));
}
