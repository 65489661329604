
import axios from 'axios';
import { supplant } from '@/common/utils/string.utils';
import type { IConfig } from 'swrv';
import type { IResponse } from 'swrv/dist/types'
import commonUtils from '@common/utils/common.utils';

interface Resource {
    name: string;
    query?: string;
    url?: string;
    method?: 'post' | 'get';
    isGraphql?: boolean;
}

interface Resources {
    [key: string]: Resource;
}

interface Options {
    isSwr?: boolean;
    supplants?: { [key: string]: string; };
    customParams?: { [key: string]: string; };
    key?: string;
    afterFix?: string;
    dependent?: any;
    swrOptions?: IConfig;
}

type WrapperedFunction = <Data, Error = any, P extends Options = {} >(data: any, options?: P) =>
    P['isSwr'] extends true ? IResponse<Data, Error> : [Error, Data];
export default function swrWrapper<T extends Resources>(resources: T): { [key in keyof T]: WrapperedFunction; } {
    const apis: any = {};
    Object.entries(resources).forEach(([apiKey, resource]: [string, Resource]) => {
        const envConfigs = (process.env as any)?.VUE_APP_CONFIGS || {};
        const host = envConfigs.backend || '';
        const { url = '/graphql', method = 'post', query, isGraphql = true, name } = resource;

        apis[apiKey] = (data: any = {}, options: Options = {}) => {
            const { supplants, customParams, swrOptions, afterFix = '', key, isSwr = true } = options;
            let params: any = {
                headers: {},
                method: method.toUpperCase(),
                url: host + (supplants ? supplant(url, supplants) : url)
            };
            const methodKey = params.method === 'GET' ? 'params' : 'data';
            if (data) {
                params[methodKey] = isGraphql
                    ? {
                        operationName: name,
                        query,
                        variables: data
                    }
                    : data;
            }

            if (customParams)  params = Object.assign(params, customParams);

            const fetcher = () => axios
                .request(params)
                .then(res => {
                    let resData = res.data;
                    const { code, message, status } = res.data || {};
                    if (status && status.toLowerCase() === 'error') {
                        const rj = {
                            code,
                            message,
                            data: res.data
                        };
                        return Promise.reject(rj);
                    }
                    if (isGraphql) {
                        const { data } = res.data || {};

                        if (data) {
                            const keys = Object.keys(data || {});
                            resData = keys.length === 1 ? data[keys[0]] : data;
                        } else {
                            return Promise.reject({ code: 'NO_CONTENT' });
                        }
                    }
                    return Promise.resolve(resData);
                })
                .catch(error => {
                    if (axios.isCancel(error)) {
                        return Promise.reject(error);
                    }
                    if (error.response && error.response.data) {
                        return Promise.reject(error.response.data);
                    }
                    return Promise.reject(error);
                });
            if (isSwr) {
                return fetcher
            }
            else 
                return commonUtils.awaitWrap(fetcher());
        }
    });
    return apis;
};