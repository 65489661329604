import { ref, onMounted, onUnmounted } from '@vue/composition-api'

export function useMouseDragScroll() {
    const scrollDOM = ref<HTMLElement>();
    let lastScrollLeft = 0;
    let startX = 0;

    const handleMouseMove = (event: MouseEvent) => {
        if (!scrollDOM.value) return;
        if (Math.abs(event.x - startX) <= 16 || Math.abs(event.movementX) < 1) return;
        const newScrollLeft = scrollDOM.value.scrollLeft - event.movementX;
        if (lastScrollLeft === newScrollLeft) return;
        scrollDOM.value.scrollLeft = newScrollLeft;
        lastScrollLeft = newScrollLeft;
    }

    const handleMouseUp = (event: MouseEvent) => {
        startX = 0;
        document.removeEventListener('mousemove', handleMouseMove, true);
        document.removeEventListener('mouseup', handleMouseUp, true);
    }

    const handleMouseDown = (event: MouseEvent) => {
        if (event.button !== 0) return;
        startX = event.x;
        event.preventDefault();
        document.addEventListener('mouseup', handleMouseUp, true);
        document.addEventListener('mousemove', handleMouseMove, true);
    }

    onMounted(() => {
        if (!scrollDOM.value) return;
        lastScrollLeft = scrollDOM.value.scrollLeft;
        scrollDOM.value.addEventListener('mousedown', handleMouseDown);
    });

    onUnmounted(() => {
        scrollDOM.value?.removeEventListener('mousedown', handleMouseDown);
    });

    return {
        scrollDOM
    }
}