import { ref, onActivated, onDeactivated, onMounted, onUnmounted, nextTick } from '@vue/composition-api'
import ResizeObserver from 'resize-observer-polyfill';
import { getMainScroll } from '@ptengine/pt-ui-x-utils/getScroll'
import { getElementPageTop } from '@ptengine/pt-ui-x-utils/elementInfo'
import debounce from '@ptengine/pt-ui-x-utils/throttle-debounce/debounce';

export function useTransformFixed() {
    const fixedDOM = ref<HTMLElement>();
    let pageTop: number | null = null;
    let defaultPosition = '';
    let ro: ResizeObserver | null = null;
    let inFixed: HTMLElement | null | boolean = null;
    const mainScroll = getMainScroll();

    const handleScroll = () => {
        if (!fixedDOM.value || pageTop === null) return;
        const scrollTop = mainScroll.scrollTop;

        if (scrollTop > pageTop) {
            if (!inFixed) {
                if (!fixedDOM.value) return;
                inFixed = true;
                if (defaultPosition !== 'absolute' && defaultPosition !== 'fixed' && defaultPosition !== 'sticky')
                    fixedDOM.value.style.position = 'absolute';
            }
            if (!fixedDOM.value) return;
            fixedDOM.value.style.transform = `translate3d(0, ${scrollTop - pageTop - 2}px, 0)`;
        } else {
            if (inFixed) {
                window.requestAnimationFrame(() => {
                    if (inFixed) inFixed = null;
                    if (!fixedDOM.value) return;
                    if (defaultPosition !== 'absolute' && defaultPosition !== 'fixed' && defaultPosition !== 'sticky')
                        fixedDOM.value.style.position = 'static';
                    fixedDOM.value.style.transform = '';
                    fixedDOM.value.style.transition = ''
                });
            }
        }
    }


    const bindScroll = () => nextTick(() => {
        if (!fixedDOM.value || pageTop !== null) return;
        pageTop = getElementPageTop(fixedDOM.value);
        defaultPosition = getComputedStyle(fixedDOM.value)?.position;
        
        const observerFunc = debounce(233, false, () => {
            if (!fixedDOM.value) return;
            pageTop = getElementPageTop(fixedDOM.value);
            handleScroll();
        }) as () => void;
        ro = new ResizeObserver(observerFunc);
        ro.observe(fixedDOM.value);

        setTimeout(() => handleScroll(), 200);
        window.addEventListener('scroll', handleScroll);
    });

    const unbindScroll = () => {
        window.removeEventListener('scroll', handleScroll);
        if (ro) {
            ro.disconnect();
            ro = null;
        }
        pageTop = null;
    }


    onActivated(bindScroll);
    onDeactivated(unbindScroll);
    onMounted(bindScroll);
    onUnmounted(unbindScroll);

    return ({
        fixedDOM
    });
}