import { isDOMElement } from './../../../utils/isDOMElement';
import { formatStyleObjectToString, StyleObject } from '../../style-object';
import { getScrollLeft, getScrollTop } from '../../../utils/getScroll';

let defaultHighlightStyle: StyleObject = {
    position: 'absolute',
    zIndex: 8998,
    border: '2px solid #13CB6E',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    transition: 'all .3s ease',
    pointerEvents: 'none',
    left: '0px',
    top: '0px',
};

let highlightEleExist = false;
export let highlightEle: HTMLElement | null = null;
let targetEle: HTMLElement | null = null;
let oldZIndex: string = '';

export function createHighlight(target: HTMLElement, styleObj: StyleObject = { ...defaultHighlightStyle }, fromResize: boolean, insertDOM?: string | HTMLElement) {
    return new Promise((resolve) => {
        let bindDOM: HTMLElement = (typeof insertDOM === 'string' ? document.querySelector(insertDOM) : insertDOM) as HTMLElement;
        if (!isDOMElement(bindDOM)) bindDOM = document.body;
        if (targetEle && oldZIndex !== '') targetEle.style.zIndex = oldZIndex;
        targetEle = target;
        const targetStyle = getComputedStyle(targetEle);
        const targetBoundaryFixed = targetEle.getBoundingClientRect();
        const scrollTop = getScrollTop();
        const scrollLeft = getScrollLeft();
        const targetBoundary = {
            width: targetBoundaryFixed.width,
            height: targetBoundaryFixed.height,
            top: targetBoundaryFixed.top + scrollTop,
            bottom: targetBoundaryFixed.bottom + scrollTop,
            left: targetBoundaryFixed.left + scrollLeft,
            right: targetBoundaryFixed.right + scrollLeft
        };
    
        oldZIndex = targetStyle.zIndex;
        // targetEle.style.zIndex = '10005';
    
        if (!styleObj.width) styleObj.width = `${targetBoundary.width + 16}px`;
        if (!styleObj.height) styleObj.height = `${targetBoundary.height + 16}px`;
        styleObj.left = targetBoundary.left
            - (
                parseInt(String(styleObj.width))
                - parseInt(String(targetStyle.width))
            ) / 2
            + 'px';
        styleObj.top = targetBoundary.top
            - (
                parseInt(String(styleObj.height))
                - parseInt(String(targetStyle.height))
            ) / 2
            + 'px';
    
        if (!highlightEle) {
            highlightEle = document.createElement('div');
            highlightEle.classList.add('guide-highlight');
        }
        else highlightEleExist = true;
        highlightEle.style.cssText = formatStyleObjectToString({ ...defaultHighlightStyle, ...styleObj });
    
        if (!highlightEleExist || fromResize) {
            bindDOM.append(highlightEle);
            resolve(highlightEle);
        } else {
            // if (tooltipEle) tooltipEle.style.opacity = '0';
            highlightEle.addEventListener('transitionend', () => {
                // if (tooltipEle) tooltipEle.style.opacity = '1';
                resolve(highlightEle);
            });
        }
    });

}

export function deleteHighlight() {
    if (targetEle) {
        targetEle.style.zIndex = oldZIndex;
        targetEle = null;
        oldZIndex = '';
    }

    if (highlightEle) {
        highlightEle.remove();
        highlightEle = null;
        highlightEleExist = false;
    }
}


export function setDefaultHighlightStyle(styleObj: StyleObject) {
    if (typeof styleObj !== 'object') return;
    defaultHighlightStyle = { ...defaultHighlightStyle, ...styleObj };
}

const css = `
    @keyframes guide-highlight-scale {
        0% { transform: scale(1); }
        50% { transform: scale(1.08);}
        100% { transform: scale(1);}
    }

    .guide-highlight {
        animation: guide-highlight-scale 1.4s ease-in-out infinite;     
        contain: size layout paint;   
    }

`;

const head = document.head || document.getElementsByTagName('head')[0];
const style = document.createElement('style');
style.type = 'text/css';
head?.appendChild(style);
style?.appendChild(document?.createTextNode(css));