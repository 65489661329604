import campaignApis from '@/apis/campaignTest.apis';
import store from '@/store/index';

export const useReportColumns = async (where?: { sid?: string; aid?: string }) => {
    if (!where) where = {};
    if (!where?.sid) where.sid = store.state.main?.profileInfo?.sid;
    if (!where?.aid) where.aid = store.state.main?.userInfo?.id;
    const [_, res] = (await campaignApis.getReportColumns({ where }, { isSwr: false })) as string[];
    return (res as any)?.campaignReportColumns as string[];
};

export const updateReportColumns = async (conditions: {
    sid?: string;
    campaignReportColumns: Array<string>;
    aid?: string;
}) => {
    if (!conditions || !conditions.campaignReportColumns) return null;
    if (!conditions?.sid) conditions.sid = store.state.main.profileInfo?.sid;
    if (!conditions?.aid) conditions.aid = store.state.main?.userInfo?.id;
    const [_, res] = await campaignApis.updateReportColumns(
        {
            where: { sid: conditions.sid, aid: conditions?.aid },
            data: { campaignReportColumns: conditions.campaignReportColumns }
        },
        { isSwr: false }
    );
    return (res as any)?.campaignReportColumns as string[];
};
