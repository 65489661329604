import swrWrapper from './swrWrapper';

const resources = {
    getCampaigns: {
        name: 'engagements',
        query: `query engagements($where: EngageDataWhereUniqueInput!){
            engagements(where:$where) {
                id
                name
                engageVersion
                baseURL
                createTime
                updateTime
                createBy
                updateBy
                conditions
                schedule
                hasForm
                hasVariant
                abtestType
                screenshot
                baseURL
                audiences: usergroups {
                    name,
                    source,
                    id,
                }
                status
                goals {
                    id
                    name
                }
                versions{
                    id
                    name
                    rate
                    controlGroup
                    displayConfig
                    screenshot
                    terminals{
                        id
                        type
                        popup
                        stickybar
                        inline
                        code
                        codeMode
                        codeSync
                        status
                        widgets
                    }
                }
                reportData
                firstRunTime
                lastPauseTime
            }
        }`
    },
    getEngagement: {
        name: 'getEngagement',
        query: `query getEngagement($where:EngageWhereUniqueInput!){
            engagement(where:$where){
                id
                sid
                name
                engageVersion
                baseURL                              
                hasForm
                abtestType
                status
                screenshot
                hasVariant
                schedule
                conditions
                isVersionEvent
                firstRunTime
                lastPauseTime
                usergroups{
                    id,
                    name,
                    source
                }
                goals {
                    id
                    name
                    content
                }
                versions {
                    id
                    name
                    rate
                    isSync
                    status
                    displayConfig
                    controlGroup
                    screenshot
                    terminals{
                        id
                        type
                        popup
                        stickybar
                        inline
                        codeMode
                        codeSync
                        code                       
                        status
                        widgets
                        widgetLayout
                    }
                } 
            }
        }`
    },
    getVersionData: {
        name: 'engageMultipleData',
        query: `query engageMultipleData($where: EngageDataWhereUniqueInput!){
            engageMultipleData(where: $where)
        }`
    },
    getAllGoalList: {
        name: 'getGoals',
        query: `query getGoals($where: GoalListWhereUniqueInput!){
            goals(where:$where) {
                id
                name
                content              
                description
                engagements
                currentResult
                lastResult
            }
        }`
    },
    getReportColumns: {
        name: 'accountProfile',
        query: `query accountProfile($where: AccountProfileUniqueInput!){
            accountProfile(where:$where) {
                campaignReportColumns
            }
        }`
    },
    updateReportColumns: {
        name: 'updateAccountProfile',
        query: `mutation updateAccountProfile($data: AccountProfileInput!, $where: AccountProfileUniqueInput!){
            updateAccountProfile(data:$data, where:$where) {
                campaignReportColumns
            }
        }`
    },
    getUserGroups: {
        name: 'getUsergroups',
        query: `query getUsergroups($where: UsergroupWhereUniqueInput!){
            usergroups(where: $where){
                id
                source
                name
                content
            }
        }`
    },
    getCampaignDefaultName: {
        name: 'defaultEngageName',
        query: `query defaultEngageName($where: EngageWhereUniqueInput!){
            defaultEngageName(where:$where) 
        }`
    },
    getPlatformData: {
        name: 'dataPlatformData',
        query: `query dataPlatformData($where:DataPlatformDataWhereUniqueInput!){
            dataPlatformData(where: $where)
        }`
    }
};

export default swrWrapper(resources);
