






















































































import { defineComponent, ref, watch } from '@vue/composition-api';
import settingConfig from '@/components/engagement/setting/setting.config';
import permissionUtils from '@/common/utils/permission';
import PermissionConfig from '@/common/configs/permissions.config';
import numberUtils from '@/common/utils/number.utils';
import packageUtils from '@/common/packages/verify-package/package.utils';
import { useCommit } from '@/hooks/index';
import Skeleton from '@/components/commons/Skeleton.vue';
import LazyloadCard from '@/components/commons/LazyloadCard.vue';
import { checkModuleRole, RoleModuleKey } from '@/common/services/singleSpa.service';

interface Props {
    info: any;
    dateRange: Object;
}
export default defineComponent({
    name: 'GoalsReportCard',
    props: ['info', 'dateRange'],
    setup(props: Props, { root, emit }) {
        const goal = ref(props.info);
        const commit = useCommit();
        const reload = ref(false); // 是否需要重新监听 加载
        const hasGoalRexPermission = permissionUtils.includePermissionWithStore([PermissionConfig.goalRex]);
        const goalConditionList = settingConfig.entryPatternList.filter(item => {
            if (item.value === 'Rex') return hasGoalRexPermission;
            return !item.hide;
        });
        const getDescription = (goal: any) => {
            if (!goal) return;
            const { description, content } = goal;
            const { type, subType, condition, value, where } = content;
            if (description !== '') {
                return description;
            } else {
                const conditionText = goalConditionList.find(con => condition === con.value);
                const subTypeKey = subType || 'page';
                if (type.toUpperCase() === 'URL') {
                    return root.$t('goals.condition_visitpage', {
                        condition: conditionText?.text,
                        value: value?.join(','),
                        pageType:
                            subTypeKey === 'page'
                                ? root.$t('dimension.original_page')
                                : root.$t('dimension.combined_page')
                    });
                } else {
                    let property = '';
                    let len = where?.length;
                    where?.map((item, index) => {
                        property += `${root.$t('goals.condition_event_property_symbol1')}${item.key}${root.$t(
                            'goals.condition_event_property_symbol2'
                        )}${index !== len - 1 ? root.$t('goals.condition_event_property_separat') : ''}`;
                    });
                    return property
                        ? root.$t('goals.condition_event_with_property', {
                              event: value && value.join(','),
                              property: property
                          })
                        : root.$t('goals.condition_event', {
                              event: value && value.join(',')
                          });
                }
            }
        };

        const chartSettings = {
            chart: {
                // width: 158,
                height: 136,
                padding: 0,
                marginTop: 4,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0
            },
            xAxis: {
                visible: false,
                type: 'linear'
            },
            yAxis: {
                visible: false
            },
            legend: {
                enabled: false
            },

            tooltip: {
                backgroundColor: 'transparent',
                outside: false,
                formatter: function() {
                    const points = this.points || [this.point];
                    const { x, y, key } = points[0] || {};
                    return (
                        (y !== undefined &&
                            `<div class="overview-chart--tooltip">${key}: <span>${numberUtils.formatNumber(
                                y
                            )}</span></div>`) ||
                        ''
                    );
                }
            }
        };

        const handleCommand = async (goal: any, command: 'edit' | 'delete') => {
            emit('updateCurrent', goal);
            switch (command) {
                case 'edit':
                    packageUtils.verifyPrivilege({ privilegeKey: 'goals', editType: 'EDIT' })
                        ? root.$router.push({ name: 'GoalEdit', params: { goalId: goal.id } })
                        : commit('main/SET_AUTH_DIALOG_VISIBLE', { visible: true, type: 'EDIT_GOAL' });
                    break;
                case 'delete':
                    emit('delete');
                    break;
            }
        };
        // 操作项
        const moreOptions = [
            {
                name: root.$t('users.users_operation_edit'),
                code: 'edit',
                roleKey: 'goalUpdate'
            },
            {
                name: root.$t('users.user_report_delete'),
                code: 'delete',
                roleKey: 'goalDelete',
                divided: true
            }
        ].filter(item => checkModuleRole(item.roleKey as RoleModuleKey));

        watch(
            () => props.info,
            () => {
                goal.value = props.info;
            },
            {
                deep: true
            }
        );

        watch(
            () => props.dateRange,
            () => {
                // emit('getData', goal.value);
                reload.value = true;
                setTimeout(() => {
                    reload.value = false;
                }, 0);
            },
            {
                deep: true
            }
        );

        const handleCardShow = () => {
            // if (props.info?.chartData !== undefined && !reload.value) return;
            emit('getData', goal.value);
        };

        return {
            props,
            goal,
            moreOptions,
            chartSettings,
            reload,
            handleCommand,
            getDescription,
            handleCardShow
        };
    },
    components: {
        Skeleton,
        LazyloadCard
    }
});
