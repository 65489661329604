import CampaignSettingStore from '@/components/campaign/setting/campaign.store';
import type { State, Getters } from './Types';
import type { GettersPattern } from '@store/index';


let campaignStore: CampaignSettingStore | undefined = undefined;
const getters: GettersPattern<'campaign', State, Getters> = {
    campaignStore: () => {
        if  (!campaignStore) campaignStore = new CampaignSettingStore();
        return campaignStore;
    },

    test: (a, b, c, d) => {
        return 1;
    },
}

export default getters;