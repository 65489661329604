import store from '@/store';
import router from '@/router/router';
import { roleLimited, rolesNew, Role, RoleLimit } from '@/common/configs/roleNew.config';

const hasEditRole = (role: Role) => {
    return Boolean(rolesNew[role]?.canEdit);
};

const includeRoleWithStore = (key: RoleLimit, role: Role) => {
    return roleLimited[key] ? hasEditRole(role) : true;
};

const changeRouterToCampaignReport = (role: Role) => {
    router.push({
        name: 'UsersReport',
        params: {
            sid: (store.state.main.profileInfo as any)?.sid
        }
    });
};

export default {
    hasEditRole,
    includeRoleWithStore,
    changeRouterToCampaignReport
};
