import roleUtils from '@/common/utils/roleNew';
import type { State } from './Type';

const state: State = {
    scrollbar: null,
    envConfigs: null,
    routerIsLoading: true,
    userInfo: null,
    profiles: null,
    profileInfo: null,
    packageInfo: null,
    profileTrackingCode: null,
    hideMainMenu: false,
    authDialogVisible: false,

    profileGuideInfo: null, //档案级别的引导信息
    accountProfileInfo: null,
    profileCollaborativeUser: null, //档案下的协作用户列表

    queryToken: [],
    asideIsFolded: false, //左侧导航收起状态
    // roleList: ['read'], //身份信息
    permissionsDict: [], //权限字典表
    permissionList: [] //权限列表
};

const getters = {
    authDialogVisible: (state: State) => state.authDialogVisible,
    envConfigs: (state: State) => state.envConfigs,
    routerIsLoading: (state: State) => state.routerIsLoading,
    userInfo: (state: State) => state.userInfo,
    profiles: (state: State) => state.profiles,
    profileInfo: (state: State) => state.profileInfo,
    packageInfo: (state: State) => state.packageInfo,
    profileTrackingCode: (state: State) => state.profileTrackingCode,
    hideMainMenu: (state: State) => state.hideMainMenu,
    canEdit: (state: State) => roleUtils.hasEditRole(state.profileInfo!?.currentUserRole)
};

export default {
    state,
    getters
};
