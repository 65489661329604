

























































































import { defineComponent, ref, watch } from '@vue/composition-api';
import { useCommit } from '@/hooks/index';
import axios from 'axios';
import dateUtils from '@/common/utils/date.utils';
import { checkPackageAuth, checkModuleRole } from '@/common/services/singleSpa.service';
import analyticsService from '@/common/services/analytics.service';
import commonUtils from '@/common/utils/common.utils';
import numberUtils from '@/common/utils/number.utils';
import csvUtils from '@/common/utils/csv.util';
import usersApis from '@/apis/users.apis';
import { useRouter } from '@hooks/index';
import LazyloadCard from '@/components/commons/LazyloadCard.vue';
import VerifyPackage from '@/common/packages/verify-package/VerifyPackage.vue';
import Skeleton from '@/components/commons/Skeleton.vue';
import userGroupConfig from '@/components/users/usergrp-config';

interface Props {
    info: any;
    profileInfo: any;
}
export default defineComponent({
    name: 'UsersReportCard',
    props: ['info', 'profileInfo'],
    setup(props: Props, { emit, root }) {
        const router = useRouter();
        const commit = useCommit();
        const cellInfo = ref(props.info);
        const requestId = 'FETCH_USER_LIST_ID';
        const moreOptions = [
            {
                name: root.$t('users.user_report_view'),
                code: 'viewGroup',
                roleKey: 'userGroupViewSegment'
            },
            {
                name: root.$t('users.users_operation_edit'),
                code: 'editGroup',
                authKey: 'EDIT_USER_GROUP',
                privilegeKey: 'userGroups',
                editType: 'EDIT',
                roleKey: 'userGroupUpdate'
            },
            {
                name: root.$t('users.user_report_export'),
                code: 'exportUserList',
                roleKey: 'userGroupExportUserList'
            },
            {
                name: root.$t('users.user_report_dulplicate'),
                code: 'dulplicate',
                privilegeKey: 'userGroups',
                authKey: 'CREATE_USER_GROUP',
                editType: 'CREATE',
                roleKey: 'userGroupCopy'
            },
            {
                name: root.$t('users.user_report_delete'),
                code: 'delete',
                divided: true,
                roleKey: 'userGroupDelete'
            }
        ].filter(item => item.code && (item.roleKey ? checkModuleRole(item.roleKey) : true));

        const handleCommand = async (group, command) => {
            emit('choose', group);
            switch (command) {
                case 'editGroup':
                    analyticsService.usePTX('ug_edit', { position: 'ug_list' }).track();
                    router.push({ name: 'UserEdit', params: { usersId: group.id } });
                    break;
                case 'viewGroup':
                    router.push({ name: 'UsersSettingReport', params: { usersId: group.id } });
                    break;
                case 'delete':
                    emit('delete');
                    break;
                case 'dulplicate':
                    usersDuplicate();
                    break;
                case 'engageWithThem':
                    checkPackageAuth('USE_CAMPAIN_USERGROUPS', 'Edit', () => {
                        analyticsService.usePTX('ug_engage_users').track();
                        analyticsService
                            .usePTX('cp_add_click', { position: 'ug_engage_users', editor_version: 'v2' })
                            .track();
                        router.push({ name: 'CampaignCreate', params: { usersId: group.id } });
                    });
                    break;
                case 'exportUserList':
                    analyticsService.usePTX('ug_export', { position: 'ug_list' }).track();
                    downloadUserList();
                    break;
            }
        };

        const showAuthDialog = key => {
            emit('showAuthDialog', key);
        };

        const formatNumber = num => {
            return (num !== undefined && numberUtils.formatNumber(num)) || 0;
        };

        watch(
            () => props.info,
            () => {
                cellInfo.value = props.info;
            },
            {
                deep: true
            }
        );

        return {
            cellInfo,
            moreOptions,

            handleCommand,
            showAuthDialog,
            formatNumber,
            handleCardShow
        };

        function handleCardShow() {
            if (cellInfo.value?.users !== undefined) return;
            emit('getData', cellInfo.value);
        }

        async function usersDuplicate() {
            const { name, content, description, guideType } = cellInfo.value;
            const [err, data] = await commonUtils.awaitWrap(
                usersApis.createUsergroup(null, {
                    data: {
                        sid: props.profileInfo.sid,
                        name: name + ' copy',
                        content,
                        guideType,
                        description
                    }
                })
            );
            if (err) {
                root.$message.error('dulplicate user group failed.');
            } else {
                typeof data?.count === 'number' &&
                    commit('main/UPDATE_PACKAGE_USAGE', { type: 'userGroups', count: data.count });
                analyticsService.usePTX('ug_add_success', { position: 'ug_list_duplicate' }).track();
                router.push({
                    name: 'UsersSettingReport',
                    params: {
                        usersId: data.id
                    }
                });
            }
        }

        /**
         * 导出该用户组下用户数据
         */
        async function downloadUserList() {
            axios.cancel(requestId);
            const chartHeader = userGroupConfig.userListCsvHeader.map(item => item.name);
            const [err, detailData] = await commonUtils.awaitWrap(requestUserList(1000));
            if (err) {
                root.$message.error('Fetch chart data fail!');
            } else if (!axios.isCancel(detailData)) {
                const { user_list } = detailData;
                const chartData = [chartHeader].concat(
                    (user_list &&
                        user_list.map(item => {
                            return userGroupConfig.userListCsvHeader.map(row => {
                                if (row.patternType.includes('TIME')) {
                                    try {
                                        // 时间戳类型
                                        if (item[row.code] && item[row.code].length === 13) {
                                            return dateUtils.formatTimezone(
                                                Number(item[row.code]),
                                                props.profileInfo.timeZone,
                                                'YYYY/MM/DD HH:mm:ss'
                                            );
                                        }
                                    } catch (e) {
                                        return item[row.code] || '--';
                                    }
                                }
                                return item[row.code] || '--';
                            });
                        })) ||
                        []
                );
                csvUtils.init('userList', cellInfo.value?.name || 'userGroup', chartData).download();
            }
        }
        /**
         * 获取该用户组条件下的所有用户
         */
        function requestUserList(limit = 100) {
            const properties = userGroupConfig.userListCsvHeader.reduce((acc, cur) => {
                const { code, type, backup, patternType } = cur;
                acc.push({
                    key: code,
                    type,
                    patternType
                });
                return acc;
            }, []);
            return usersApis.detailData(
                null,
                {
                    where: {
                        sid: props.profileInfo.sid,
                        funName: 'userList',
                        audienceFilter: cellInfo.value.content,
                        timeZone: props.profileInfo.timeZone,
                        limit,
                        properties
                    }
                },
                { requestId: requestId }
            );
        }
    },
    components: {
        VerifyPackage,
        Skeleton,
        LazyloadCard
    }
});
