import router from '@/router/router';
import type VueRouter from 'vue-router';
import type { Route } from 'vue-router';
import { getCurrentInstance, reactive, watch } from '@vue/composition-api'

// 与vue3 router保持一致
// 返回的是reactive对象，注意别解构使用，会丢失响应性，要解构使用得自行computed.
export function useRoute() {
    const { proxy }: any = getCurrentInstance();
    const route = reactive<Route>({ ...proxy?.$route } as Route);
    watch(() => proxy?.$route, () => {
        if (typeof proxy?.$route  === 'object')
            for (let property in proxy?.$route) {
                route[property as 'path'] = proxy?.$route[property as 'path'];
            }
    });
    return route;
}

export function useRouter(): VueRouter {
    return router;
}