import { onActivated, nextTick, ref, onDeactivated, watch } from '@vue/composition-api';
import { useState } from './useStore';
import { useRoute } from '../utils/useRouter';
import { getScrollTop } from '@ptengine/pt-ui-x-utils/getScroll';

let scrollTop = 0;
let scrollLeft = 0;
let lastSid = '';

export const useRememberScroll = (scroll?: boolean) => {
    const { scrollbarTop, scrollbarLeft } = useState('campaign', ['scrollbarLeft', 'scrollbarTop']);
    const { params: { sid } } = useRoute();

    const leavedCampaign = ref<string>('');
    onActivated(() => {
        nextTick(() => {
            if (lastSid !== '' && lastSid !== sid) return;
            if (!scroll || !scrollbarLeft.value || !scrollbarTop?.value) return;
            (scrollbarTop.value as any).scrollTop = scrollTop;
            (scrollbarLeft.value as any).scrollLeft = scrollLeft;
        });
    });

    onDeactivated(() => {
        leavedCampaign.value = '';
    });

    const rememberScroll = (campaignId?: string) => {
        if (campaignId) leavedCampaign.value = campaignId;
        lastSid = sid.slice();
        console.log(lastSid, sid);
        scrollTop = getScrollTop();
        scrollLeft = (scrollbarLeft.value as any)?.scrollLeft ?? 0;
    }

    return { rememberScroll, leavedCampaign }
}