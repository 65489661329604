import type { State, Mutations } from './Types';
import type { MutationsPattern } from '@store/index';

const mutations: MutationsPattern<State, Mutations> = {
    UPDATE_CAMPAIGN_SETTING_CACHE(state, campaign: State['campaignSettingCache']) {
        state.campaignSettingCache = campaign;
    },
    UPDATE_CREATE_PORTAL(state, newCreatePortal: State['createPortal']) {
        state.createPortal = newCreatePortal;
    },
    UPDATE_CAMPAIGN_DETAILS(state, campaign: State['campaignDetails']) {
        state.campaignDetails = campaign;
    },
    UPDATE_DETAILS_DATE_RANGE(state, dateRange: State['campaignDetailsDateRange']){
        state.campaignDetailsDateRange = dateRange;
    },
    UPDATE_DETAILS_ALL_TIMERANGE(state, allTimeRange: State['campaignDetailsAllTimeRange']) {
        state.campaignDetailsAllTimeRange = allTimeRange;
    },
    setScrollbarLeft(state, scrollBar: State['scrollbarLeft']) {
        if (!scrollBar) return false;
        state.scrollbarLeft = scrollBar;
        return true;
    },
    setScrollbarTop(state, scrollBar: State['scrollbarTop']) {
        if (!scrollBar) return false;
        state.scrollbarTop = scrollBar;
        return true;
    },
    updateCount(state, newCount: number) {
        state.count = newCount;
    }
}
export default mutations;
