import campaignApis from '@/apis/campaignTest.apis';
import store from '@/store/index';
import dateUtils from '@/common/utils/date.utils';
import pValueCalculate from '@common/utils/pValue';
import type { RemoteCampaign, Data, Version } from '@/common/types/CampaignTypes';


export type Campaign = OverWrite<ConfigData, VisitorData & CreaterData & ThumbnailData>;

type ConfigData = Omit<RemoteCampaign, 'reportData'> & {
    editorStatus: {
        devices: Array<'Mobile' | 'PC'>;
        changes: Array<'Inline' | 'Popup' | 'Stickybar'>;
    };
    belong?: string;
};

interface VisitorData {
    engageView: Data;
    engageClick: Data;
    engageClose: Data;
    engageExit: Data;
    avgVisitTime: Data;
    bounceRate: Data;
    sessionViewDepth: Data;
    engageSubmit?: Data;
    engageGoal: Array<Data & { name: string }>;
}

interface CreaterData {
    createTime: Data;
    updateTime: Data;
    createBy?: Data;
    updateBy?: Data;
    firstRunTime?: Data;
    lastPauseTime?: Data;
}

interface ThumbnailData {
    thumbnails: Array<Thumbnail>;
}

function formatConfigData(remoteData: RemoteCampaign, versionID?: string): ConfigData {
    // console.log('formatConfigData remoteData ====>', remoteData);
    const res: any = {
        ...remoteData,
        conditions: remoteData?.conditions || [],
        schedule: remoteData?.schedule || [],
        editorStatus: {
            devices: [],
            changes: []
        }
    };
    delete res.reportData;
    let searchVersion = versionID
        ? [remoteData.versions?.find(version => version.id === versionID) as Version]
        : remoteData.versions ?? [];

    if (versionID && remoteData.versions?.find(version => version.id === versionID)?.controlGroup === true)
        searchVersion = remoteData.versions ?? []; // 对照组也全搜

    if (
        searchVersion?.some(version => version.terminals.some(terminal => terminal.type === 1 && terminal.status === 1))
    )
        res.editorStatus.devices.push('Mobile');
    if (
        searchVersion?.some(version => version.terminals.some(terminal => terminal.type === 2 && terminal.status === 1))
    )
        res.editorStatus.devices.push('PC');
    if (
        searchVersion?.some(version =>
            version.terminals.some(terminal => terminal.popup?.length >= 1 && terminal.status === 1)
        )
    )
        res.editorStatus.changes.push('Popup');
    if (
        searchVersion?.some(version =>
            version.terminals.some(terminal => terminal.inline?.length >= 1 && terminal.status === 1)
        )
    )
        res.editorStatus.changes.push('Inline');
    if (
        searchVersion?.some(version =>
            version.terminals.some(terminal => terminal.stickybar?.length >= 1 && terminal.status === 1)
        )
    )
        res.editorStatus.changes.push('Stickybar');
    if (
        res.editorStatus.changes.length === 0 &&
        searchVersion?.some(version => version.terminals.some(terminal => terminal.code && terminal.status === 1))
    )
        res.editorStatus.changes.push('CodeMode');

    if (!versionID) res.versionScreenshot = remoteData.versions?.[0]?.screenshot;
    else res.versionScreenshot = remoteData.versions?.find(version => version.id === versionID)?.screenshot;
    return res;
}

function formatCreaterData(remoteData: RemoteCampaign): CreaterData {
    const res: any = {
        updateBy: { count: remoteData?.updateBy },
        createBy: { count: remoteData?.createBy },
        updateTime: { count: remoteData?.updateTime ? (new Date(remoteData?.updateTime) as any)?.format() : '--' },
        createTime: { count: remoteData?.createTime ? (new Date(remoteData?.createTime) as any)?.format() : '--' },
        lastPauseTime: {
            count: remoteData?.lastPauseTime ? (new Date(remoteData?.lastPauseTime) as any)?.format() : '--'
        },
        firstRunTime: { count: remoteData?.firstRunTime ? (new Date(remoteData?.firstRunTime) as any)?.format() : '--' }
    };
    return res;
}

function formatCount(count: number) {
    if (typeof count !== 'number' || !count) return '0';
    return parseFloat(count?.toFixed(2)) + '';
}

function formatRate(rate: number) {
    if (typeof rate !== 'number' || !rate) return '0%';
    return (rate * 100)?.toFixed(2) + '%';
}

function formatData(current: { count: any; rate: any }, last: { count: any; rate: any }) {
    const res = { count: '', rate: '' };
    res.count = formatCount(current.count ? current.count : 0);
    res.rate = formatRate(current.rate ? current.rate : 0);
    return res;
}

function formatVisitorData(
    remoteData: RemoteCampaign,
    controlGroupData?: RemoteCampaign['reportData']['userCount']
): VisitorData {
    const { goals = [], reportData, hasForm } = remoteData;
    const data = reportData?.userCount ?? {};
    const res: any = {} as VisitorData;
    for (const dataKey in data) {
        const key = dataKey as keyof typeof data;
        if (!data[key]) res[key] = key === 'engageGoal' ? [] : { count: '0', rate: '0' };
        else {
            if (key === 'engageGoal') {
                res[key] = data[key]?.map(goal => {
                    const goalRes: any = {
                        name: goals?.find(targetGoal => targetGoal.id === goal.id)?.name,
                        id: goal.id,
                        ...formatData(goal?.current, goal.last)
                    };
                    if (controlGroupData) {
                        const controlVisitors = controlGroupData?.engageView?.current?.count;
                        const controlConversions = parseInt(
                            controlGroupData?.engageGoal?.find(controlGoal => controlGoal.id === goal.id)?.current
                                ?.count ?? '0'
                        );
                        const controlRate = controlConversions / controlVisitors;
                        const variationVisitors = data?.engageView?.current?.count;
                        const variationConversions = parseInt(
                            data?.engageGoal?.find(controlGoal => controlGoal.id === goal.id)?.current?.count ?? '0'
                        );
                        const variationRate = variationConversions / variationVisitors;

                        goalRes.lifting = !controlRate
                            ? '∞'
                            : (((variationRate - controlRate) / controlRate) * 100).toFixed(2) + '%';
                        if (parseInt(goalRes.lifting) > 0) goalRes.lifting = '+' + goalRes.lifting;
                        goalRes.confidence = pValueCalculate({
                            controlVisitors,
                            controlConversions,
                            variationVisitors,
                            variationConversions
                        });
                    }
                    return goalRes;
                });
            } else if ((key as any) === 'avgVisitTime')
                res[key] = { count: dateUtils.getDuration((data[key] as any)?.current?.count) };
            else if ((key as any) === 'engageSubmit')
                hasForm ? (res[key] = formatData(data[key]?.current as any, data[key]?.last as any)) : null;
            else res[key] = formatData(data[key]?.current as any, data[key]?.last as any);
        }
    }
    return res;
}

interface Thumbnail {
    deviceType: 'pc' | 'mobile' | 'tablet';
    htmlString?: string;
    url?: string;
    customDomSelector: string;
    addFix?: false;
    imgSrc?: string;
}

export const useCampaignReport = async (where: {
    id?: string;
    sid?: string;
    startTime: string;
    endTime: string;
    status?: 0 | 1 | 2;
    mysqlFlag?: boolean;
    removeConfigFlag?: true;
}) => {
    if (!where.sid) where.sid = store.state.main.profileInfo?.sid;
    where.removeConfigFlag = true;
    const [err, campaigns] = await campaignApis.getCampaigns<Array<RemoteCampaign>>({ where }, { isSwr: false });
    if (err) return false;
    const res: any = [];
    await Promise.all(
        campaigns?.map(async campaign => {
            const campaignRes = {
                ...formatConfigData(campaign),
                ...formatVisitorData(campaign),
                ...formatCreaterData(campaign)
                // displayConfig: campaign.displayConfig
            };
            res.push(campaignRes);
        })
    );
    return res as Array<Campaign>;
};

type VersionCampaign = Campaign & { belong: string; level: 1 };
export const getVersionData = async (
    where: { sid?: string; startTime: string; endTime: string; engageID: string; mySqlFlag?: boolean },
    config: {
        versions: RemoteCampaign['versions'];
        goals: RemoteCampaign['goals'];
        hasForm: RemoteCampaign['hasForm'];
        engageVersion: RemoteCampaign['engageVersion'];
    }
) => {
    if (!where.sid) where.sid = store.state.main.profileInfo?.sid;
    const [_, versionsData] = await campaignApis.getVersionData<Array<RemoteCampaign & { versionID: string }>, {}>(
        { where: { ...where, sequentialFlag: true, multiVersion: true } },
        { isSwr: false }
    );
    versionsData?.sort(
        (versionA, versionB) =>
            config.versions.findIndex(version => versionA.versionID === version.id) -
            config.versions.findIndex(version => versionB.versionID === version.id)
    );
    const controlGroupId = (config.versions.find(version => version.controlGroup) ?? config.versions[0])?.id;
    const controlGroupData = versionsData?.find(version => version.versionID === controlGroupId)?.['reportData']?.[
        'userCount'
    ];
    const res: Array<any> = [];
    await Promise.all(
        versionsData.map(async version => {
            const versionsData = config.versions.find(v => v.id === version.versionID);
            const campaignRes = {
                belong: where.engageID,
                level: 1,
                ...formatConfigData(
                    ({ ...version, ...config, id: version.versionID } as unknown) as RemoteCampaign,
                    version.versionID
                ),
                ...formatVisitorData(
                    ({
                        ...version,
                        ...config,
                        id: version.versionID,
                        name: versionsData?.name
                    } as unknown) as RemoteCampaign,
                    controlGroupId !== version.versionID ? controlGroupData : undefined
                ),
                name: versionsData?.name,
                versionDetail: {
                    ...versionsData,
                    asControlGroup: version.versionID === controlGroupId,
                    rate: ((versionsData?.rate ?? 0) * 100)?.toFixed(0) + '%'
                }
            };
            res.push(campaignRes);
        })
    );
    let controlVersion: number | VersionCampaign = res.findIndex(version => version?.versionDetail?.asControlGroup);
    if (controlVersion !== -1) controlVersion = (res.splice(controlVersion, 1)[0] as unknown) as VersionCampaign;
    res.unshift(controlVersion as VersionCampaign);
    return res as Array<VersionCampaign>;
};