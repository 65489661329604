import type { State, Getters, Actions, Mutations } from './Types';
import type { ActionsPattern } from '@store/index';

const actions: ActionsPattern<'campaign', State, Getters, Mutations, Actions> = {
    test: (a) => {
        a.dispatch('campaign/test2', '123');
        return 1;
    },
    test2: (context, payload) => {
        context.dispatch('campaign/test', 123);
        return '123';
    }
};

export default actions;