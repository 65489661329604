import type VueI18n from 'vue-i18n';
import { getCurrentInstance } from '@vue/composition-api'
export interface I18n {
    t(key: VueI18n.Path, values?: VueI18n.Values): VueI18n.TranslateResult;
    t(key: VueI18n.Path, locale: VueI18n.Locale, values?: VueI18n.Values): VueI18n.TranslateResult;  
    locale: string
}

export function useI18n(): I18n {
    const { proxy }: any = getCurrentInstance();
    const locale = proxy.$locale.current();
    return { 
        t: proxy?.$t.bind(proxy) as typeof VueI18n.prototype.t,
        locale
    };
}