import { isDOMElement } from './../../utils/isDOMElement';
import { lock, clearBodyLocks } from '../body-scroll-lock';
import type { StyleObject } from '../style-object';
import { createMask, deleteMask } from './mask';
import { createHighlight, deleteHighlight, highlightEle } from './highlight';
import { createTooltip, deleteTooltip, TooltipConfig, tooltipEle } from './tooltip';


export interface GuideOptions {
    interactive?: boolean;
    clickable?: boolean;
    tooltipConfig?: TooltipConfig;
    clickMaskToStop?: boolean;
    onStart?: (e?: MouseEvent) => void;
    onStop?: (e?: MouseEvent) => void;
    maskStyle?: StyleObject;
    highlight?: boolean;
    highlightStyle?: StyleObject;
    onClickTarget?: (e?: MouseEvent) => void;
    onClickMask?: (e?: MouseEvent) => void;
    onClickWhiteList?: (e?: MouseEvent) => void;
    getScrollLeft?: Function;
    getScrollTop?: Function;
    whiteList?: Array<string | HTMLElement>;
    lockScroll?: boolean;
    insertDOM?: string | HTMLElement;
    renderTooltip?: Function;
}

let inGuide = false;
let defaultGuideOptions: GuideOptions = {
    clickMaskToStop: false, // 点击蒙层触发stop
    interactive: true, // 蒙层下元素是否可交互
    clickable: false, // 蒙层下元素是否可点击，不可交互的必然不可点击。
    highlight: true,
    lockScroll: true,
};

let stopFunc: Function | undefined;

export function start(targetEle: HTMLElement | string | null, guideOptions: GuideOptions, fromResize: boolean) {
    if (typeof targetEle === 'string') targetEle = document.querySelector(targetEle) as HTMLElement;
    if (targetEle !== null && !isDOMElement(targetEle)) return;
    if (targetEle === null || (highlightEle && !guideOptions?.highlight)) deleteHighlight();
    console.log(tooltipEle, guideOptions?.tooltipConfig)
    if (tooltipEle && !guideOptions?.tooltipConfig) deleteTooltip();

    if (!guideOptions) guideOptions = { ...defaultGuideOptions };
    else guideOptions = { ...defaultGuideOptions, ...guideOptions };

    guideOptions?.onStart?.();
    inGuide = true;
    stopFunc = guideOptions?.onStop;
    if (guideOptions?.lockScroll) lock();
    if (targetEle && guideOptions.highlight) createHighlight(targetEle, guideOptions?.highlightStyle, fromResize, guideOptions?.insertDOM).then(highlightEle => {
        if (guideOptions?.tooltipConfig) createTooltip(highlightEle as HTMLElement || (targetEle as HTMLElement), guideOptions?.tooltipConfig, guideOptions?.insertDOM);
    });
    else if (guideOptions?.tooltipConfig) createTooltip(targetEle, guideOptions?.tooltipConfig, guideOptions?.insertDOM);

    createMask(targetEle, {
        styleObj: guideOptions?.maskStyle,
        interactive: guideOptions?.interactive,
        clickable: guideOptions?.clickable,
        clickMaskToStop: guideOptions?.clickMaskToStop,
        whiteList: guideOptions?.whiteList,
        onClickTarget: guideOptions?.onClickTarget,
        onClickMask: guideOptions?.onClickMask,
        onClickWhiteList: guideOptions?.onClickWhiteList,
    });
}

export function stop() {
    clearBodyLocks();
    deleteMask();
    deleteHighlight();
    deleteTooltip();
    stopFunc?.();
}

export function setDefaultGuideOption(guideOptions: GuideOptions) {
    if (typeof guideOptions !== 'object') return;
    defaultGuideOptions = { ...defaultGuideOptions, ...guideOptions };
}
