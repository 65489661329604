






import { defineComponent, onMounted, onUnmounted, ref, watch } from '@vue/composition-api';
import baseConfig from '@/common/configs/base.config';
interface Props {
    intersectionRatio: number;
    customStyle?: Object;
    reload?: boolean;
}
export default defineComponent({
    name: 'LazyloadCard',
    props: ['intersectionRatio', 'customStyle', 'reload'],
    setup(props: Props, { emit }) {
        const lazyCardRef = ref();
        const observer = ref();
        const intersectionHandler = (entries: IntersectionObserverEntry[]) => {
            if (entries[0]?.isIntersecting) {
                emit('show');
                unObserver();
            }
        };
        const unObserver = () => {
            lazyCardRef.value && observer.value && observer.value.unobserve(lazyCardRef.value);
        };

        const init = () => {
            try {
                observer.value = new window.IntersectionObserver(intersectionHandler, {
                    threshold: props.intersectionRatio || baseConfig.COMMON_INTERSECTION_RATIO
                });
                lazyCardRef.value && observer.value.observe(lazyCardRef.value);
            } catch (e) {
                console.log(e);
                // 不支持InterSectionObserve的直接调用数据
                if (window.requestAnimationFrame) {
                    window.requestAnimationFrame(() => {
                        emit('show');
                    });
                } else {
                    emit('show');
                }
            }
        };

        onMounted(() => {
            init();
        });
        onUnmounted(() => {
            unObserver();
        });

        // 重新进行监听
        watch(
            () => props.reload,
            () => {
                if (props.reload) {
                    init();
                }
            }
        );
        return {
            lazyCardRef,
            props
        };
    }
});
