"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var composition_api_1 = require("@vue/composition-api");
var validators_1 = require("../../_utils/validators");
var props = {
    type: {
        type: String,
        default: 'contain',
        validator: (0, validators_1.validateOne)(['text', 'outline', 'contain'])
    },
    size: {
        type: String,
        default: 'normal',
        validator: (0, validators_1.validateOne)(['large', 'medium', 'normal', 'small'])
    },
    color: {
        type: String,
        default: 'blue',
        validator: (0, validators_1.validateOne)(['blue', 'green', 'red', 'black', 'purple', 'white', 'grey', 'darkblue', 'orange'])
    },
    reverse: {
        type: Boolean,
        default: false
    },
    ghost: {
        type: Boolean,
        default: false
    },
    block: {
        type: Boolean,
        default: false
    },
    fullfilled: {
        type: Boolean,
        default: false
    },
    htmlType: {
        type: String,
        default: 'button',
        validator: (0, validators_1.validateOne)(['button', 'submit', 'reset'])
    },
    loading: {
        type: [Boolean, String],
        default: false,
        validator: (0, validators_1.validateOne)(['before', 'after', 'cover', false, true])
    },
    disabled: {
        type: Boolean,
        default: false
    },
    href: [String, Boolean],
    target: String,
    icon: [String, Object],
    iconAfter: [String, Object]
};
var colorTypeMap = {
    // sass在当前webpack配置下打包时， 循环语句里的变量white、black会被编译为#fff, #000
    white: 'whiteness',
    black: 'dark',
    blue: 'blue',
    green: 'green',
    red: 'red',
    purple: 'purple',
    darkblue: 'darkblue',
    orange: 'orange',
    grey: 'grey'
};
exports.default = (0, composition_api_1.defineComponent)({
    name: 'PtButton',
    props: props,
    emits: ['click'],
    setup: function (props, _a) {
        var emit = _a.emit, slots = _a.slots;
        var buttonRef = (0, composition_api_1.ref)();
        var handleClick = function (evt) {
            var _a;
            emit('click', evt);
            if (buttonRef.value)
                (_a = buttonRef.value) === null || _a === void 0 ? void 0 : _a.blur();
        };
        var hasIconBefore = (0, composition_api_1.computed)(function () { return Boolean(props.icon || slots.icon); });
        var hasIconAfter = (0, composition_api_1.computed)(function () { return !(props.icon || slots.icon) && Boolean(props.iconAfter || slots.iconAfter); });
        var icon = (0, composition_api_1.computed)(function () { return props.icon || props.iconAfter; });
        var iconConfig = (0, composition_api_1.computed)(function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
            return ({
                name: (_b = (_a = icon.value) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : icon.value,
                style: typeof icon !== 'object'
                    ? undefined
                    : __assign(__assign({}, icon.value), {
                        width: (_h = (_f = (_d = (_c = icon.value) === null || _c === void 0 ? void 0 : _c.size) !== null && _d !== void 0 ? _d : (_e = icon.value) === null || _e === void 0 ? void 0 : _e.width) !== null && _f !== void 0 ? _f : (_g = props.icon) === null || _g === void 0 ? void 0 : _g.height) !== null && _h !== void 0 ? _h : '16px',
                        height: (_p = (_m = (_k = (_j = icon.value) === null || _j === void 0 ? void 0 : _j.size) !== null && _k !== void 0 ? _k : (_l = icon.value) === null || _l === void 0 ? void 0 : _l.height) !== null && _m !== void 0 ? _m : (_o = props.icon) === null || _o === void 0 ? void 0 : _o.width) !== null && _p !== void 0 ? _p : '16px'
                    })
            });
        });
        var DOMAttributes = (0, composition_api_1.computed)(function () { return ({
            class: [
                'pt-button',
                "pt-button--".concat(props.type, "--").concat(colorTypeMap[props.color]).concat(props.reverse ? '--reverse' : ''),
                "pt-button--".concat(props.size),
                {
                    'pt-button--ghost': props.ghost,
                    'pt-button--block': props.block,
                    'pt-button--fullfilled': props.fullfilled,
                    'is-disabled': Boolean(props.loading) || props.disabled,
                    'is-loading-before': props.loading === 'before' || (props.loading === true && !hasIconAfter.value),
                    'is-loading-after': props.loading === 'after' || (props.loading === true && hasIconAfter.value),
                    'is-loading-cover': props.loading === 'cover'
                }
            ],
            disabled: props.loading || props.disabled,
            type: props.htmlType,
            href: typeof props.href === 'string' && props.href !== '' ? props.href : undefined,
            target: props.href ? props.target : undefined
        }); });
        return {
            buttonRef: buttonRef,
            handleClick: handleClick,
            DOMAttributes: DOMAttributes,
            iconConfig: iconConfig,
            hasIconBefore: hasIconBefore,
            hasIconAfter: hasIconAfter
        };
    }
});
