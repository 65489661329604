



























































import { defineComponent, onMounted, ref } from '@vue/composition-api';
import numberUtils from '@/common/utils/number.utils';
import Skeleton from '@/components/commons/Skeleton.vue';
import usersApis from '@/apis/users.apis';

interface Props {
    data: any;
    profileInfo: any;
}

export default defineComponent({
    name: 'UsersReportAllPeopleCard',
    props: ['data', 'profileInfo'],
    setup(props: Props, { emit }) {
        const allPeopleData = ref(props.data);
        onMounted(() => {
            handleLoadData();
        });
        return {
            allPeopleData,
            numberUtils
        };
        function handleLoadData() {
            const { sid, timeZone } = props.profileInfo;
            const { id, content } = allPeopleData.value;
            const contentList = [
                {
                    id,
                    children: content.children,
                    operator: content.operator
                }
            ];
            usersApis
                .getUserGroupData(null, {
                    input: {
                        sid,
                        timeZone,
                        contentList
                    }
                })
                .then(res => {
                    if (!res[0]) return;
                    // const { users, actives, newActives, id } = res[0];

                    allPeopleData.value = {
                        ...allPeopleData.value,
                        ...res[0]
                    };
                    emit('updateData', res[0]);
                });
        }
    },
    components: {
        Skeleton
    }
});
