import type { State } from './Types';
import { getCampaignReportRouteName } from '@/components/campaign/campaign.util';

const state: State = {
    campaignSettingCache: undefined, //campaign setting中暂存信息
    createPortal: getCampaignReportRouteName(),
    count: 0,
    campaignDetails: undefined, //详情页下campaign信息
    campaignDetailsDateRange: {
        startTime: '',
        endTime: '',
        code: 'userUpdate'
    }, //详情页下的时间范围, 默认使用all time
    scrollbarLeft: undefined,
    scrollbarTop: undefined,
};

export default state;
