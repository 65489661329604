import i18n from '@/assets/i18n/';

export const roleLimited = {
    createUsergroup: 1,
    editUsergroup: 1,
    copyUsergroup: 1,
    engageWithUsergroup: 1,
    deleteUsergroup: 1,
    saveUsergroup: 1,
    saveNewUsergroup: 1,
    createCampaign: 1,
    createGoal: 1,
    crossDomain: 1
};

export const rolesNew = {
    owner: {
        canEdit: true,
        name: i18n.t('user_setting.role_owner'),
        dsec: ''
    },
    admin: {
        canEdit: true,
        name: i18n.t('user_setting.role_admin'),
        desc: i18n.t('user_setting.role_admin_desc')
    },
    readonly: {
        canEdit: false,
        name: i18n.t('user_setting.role_readonly'),
        desc: i18n.t('user_setting.role_readonly_desc')
    }
};

const roles = [
    {
        key: 'owner',
        canEdit: true,
        name: i18n.t('user_setting.role_owner')
    },
    {
        key: 'admin',
        canEdit: true,
        name: i18n.t('user_setting.role_admin'),
        desc: i18n.t('user_setting.role_admin_desc')
    },
    {
        key: 'readonly',
        canEdit: false,
        name: i18n.t('user_setting.role_readonly'),
        desc: i18n.t('user_setting.role_readonly_desc')
    }
];

export type Role = keyof typeof rolesNew;
export type RoleLimit = keyof typeof roleLimited;

export default {
    roles,
    roleLimited,
    rolesNew
};
