import store, { ModuleNames, RootState, Getters, RootGetters } from '@store/index';
import { computed, Ref } from '@vue/composition-api';

// composition api lang="ts"下提供完善的类型支持
type ToRefs<T = any> = {
    readonly [K in keyof T]-?: Readonly<Ref<T[K]>>;
};

function createComputedObj(obj: Object, keys?: Array<string>) {
    if (!(obj instanceof Object)) return obj;
    const resObj: { [key: string]: any } = {};
    if (!keys || !(keys instanceof Array))
        for (let key in obj)
            resObj[key] = computed(() => (obj as any)[key]);
    else 
        for (let key of keys) {
            if (obj.hasOwnProperty(key)) resObj[key] = computed(() => (obj as any)[key]);
        }
    return resObj;
}

// 返回整个store，自行computed
export function useStore() {
    return store;
}


// useStore返回的是computed后的ref对象，在setup中记得.value使用。
// 尽量筛选需要的属性使用，减少观察者数量。
// 重载一 观察整个根state
// const { testState1 } = useState();
export function useState(): ToRefs<typeof store['state']>;
// 重载二 筛选观察根state中的状态
// const { testState2 } = useState(['testState2']);
export function useState<T extends keyof RootState>(options: Array<T>): ToRefs<Pick<typeof store['state'], T>>;
// 重载三 观察Module中的state
// const { createPortal, count } = useState('campaign');
export function useState<T extends ModuleNames>(options: T): ToRefs<typeof store['state'][T]>;
// 重载四 筛选观察Module中的state 
// const { createPortal } = useState('campaign', ['createPortal', 'count']);
export function useState<S extends ModuleNames, T extends keyof typeof store['state'][S]>(namespace: S, options: Array<T>)
    : ToRefs<Pick<typeof store['state'][S], T>>;
export function useState(param1?: unknown, param2?: unknown ) {
    if (!param1 && !param2) return createComputedObj(store?.state);
    if (param1 instanceof Array && !param2)
        return createComputedObj(store?.state, param1);
    if (typeof param1 === 'string' && store?.state[param1 as ModuleNames] && !param2)
        return createComputedObj(store?.state[param1 as ModuleNames]);
    if (typeof param1 === 'string' && store?.state[param1 as ModuleNames] && param2 instanceof Array)
        return createComputedObj(store?.state[param1 as ModuleNames], param2);

    return createComputedObj(store?.state);
}

// 当使用Module重载三、四时useGetter做了类似useState的扁平化处理。
// 重载一 观察整个根getters.返回的是原本的vuex里的getters，['rootGetter', 'moduleA/getter', ...];
// const { testGetter1 } = useGetters();
// 重载二 筛选观察根getters里的getter。
// const { testGetter1 } = useGetters(['testGetter1']);
// 重载三 观察模块里的getter。这里返回的对象会已去掉前缀。
// const { campaignStore, doubleCount } = useGetters('campaign');
// 重载四 筛选观察模块里的getter。这里返回的对象会已去掉前缀，筛选数组里的字符也不用加上前缀。
// const { campaignStore, doubleCount } = useGetters('campaign', ['campaignStore', 'doubleCount']);
// ts已经对上述规则做了处理，如果用错了会有提示。
export function useGetters(): ToRefs<Getters>;
export function useGetters<T extends keyof RootGetters>(options: Array<T>): ToRefs<Pick<RootGetters, T>>;
export function useGetters<T extends ModuleNames>(options: T): ToRefs<ProptypePrefixMap<Getters, `${T}/`>>;
export function useGetters<S extends ModuleNames, T extends IncludePrefixAndRemove<keyof Getters, `${S}/`>>
    (namespace: S, options: Array<T>): ToRefs<Pick<ProptypePrefixMap<Getters, `${S}/`>, T>>;
export function useGetters(param1?: unknown, param2?: unknown ) {
    if (!param1 && !param2) return createComputedObj(store?.getters);
    if (param1 instanceof Array && !param2)
        return createComputedObj(store?.getters, param1);
    if (typeof param1 === 'string' && !param2) {
        const computedObj = createComputedObj(store?.getters, Object.keys(store.getters).filter(key => key.startsWith(param1)));
        const res: { [key: string]: any } = {};
        Object.entries(computedObj).forEach(([key, val]) => res[key.substr(param1.length + 1)] = val);
        return res;
    }
    if (typeof param1 === 'string' && param2 instanceof Array) {
        const computedObj = createComputedObj(store?.getters, Object.keys(store.getters).filter(key => key.startsWith(param1)));
        const res: { [key: string]: any } = {};
        Object.entries(computedObj).forEach(([key, val]) => {
            const subKey = key.substr(param1.length + 1);
            if (param2.includes(subKey))
                res[subKey] = val;
        });
        return res;
    }

    return createComputedObj(store?.getters);
}


// useCommit和useDispatch没有做类似useGetters的扁平化处理，仅支持字符串检测
export function useCommit() {
    return store.commit;
}

export function useDispatch() {
    return store.dispatch;
}