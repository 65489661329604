export function getScrollLeft() {
    let t: HTMLElement | null;
    return (((t = document.documentElement) || (t = document.body.parentNode as HTMLElement)) &&
    typeof t.scrollLeft == 'number'
        ? t
        : document.body
    ).scrollLeft;
}

export function getScrollTop() {
    let t: HTMLElement | null;
    return (((t = document.documentElement) || (t = document.body.parentNode as HTMLElement)) &&
    typeof t.scrollTop == 'number'
        ? t
        : document.body
    ).scrollTop;
}

export function getMainScroll() {
    let t: HTMLElement | null;
    return ((t = document.documentElement) || (t = document.body.parentNode as HTMLElement)) &&
        typeof t.scrollTop == 'number'
        ? t
        : document.body;
}
