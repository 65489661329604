








import { defineComponent, computed } from '@vue/composition-api';

interface Props {
    customStyle?: Object;
    itemStyle?: Object;
    variant?: string;
    light?: boolean;
}
export default defineComponent({
    name: 'Skeleton',
    props: ['customStyle', 'itemStyle', 'variant', 'light'],
    setup(props: Props) {
        const computedVariant = computed(() => {
            return props.variant ?? 'caption';
        });
        return {
            computedVariant,
            props
        };
    }
});
